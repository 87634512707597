<template>
  <Modal :value="value" title="区域管理" @input="(val) => $emit('input', val)" :width="800" fullscreen @on-visible-change="modalChange">
    <x-table :no-data-text="CA('userManage_check') ? '暂无数据' : '暂无数据查询权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @add="add" @search="search" @page-change="pageChange" @page-size-change="pageSizeChange"></x-table>

    <Modal v-model="modal.show" :title="modal.title" @on-visible-change="modalShow" :width="450">
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="用户名" prop="userName">
          <Input readonly v-model="form.userName" style="width: 300px"></Input>
        </FormItem>
        <FormItem label="区域" prop="areaCode">
          <Cascader :data="areaList" v-model="form.areaCode" change-on-select :load-data="loadData" placeholder="请选择区域" style="width: 300px" @on-change="handleCascader"></Cascader>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'
import CU from '@/common/util'
export default {
  name: '',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    regionRecordsList: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '用户名称',
            mingWidth: 100,
            key: 'userName',
          },
          {
            title: '区域名称',
            mingWidth: 100,
            key: 'areaName',
          },
          {
            title: '创建时间',
            mingWidth: 100,
            key: 'createTime',
          },
          {
            title: '操作',
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA('userManage_edit') && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA('userManage_delete') && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: '新增区域',
            ca: 'userManage_add',
          },
          width: 200,
          filterBox: [
            {
              conditionName: '用户名称',
              component: 'input',
              field: 'name',
              defaultValue: '',
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      search_data: {},

      modal: {
        show: false,
        title: '',
        submitLoading: false,
      },
      form: {
        userName: '',
        userId: '',
        areaCode: '',
        areaName: '',
        createTime: '',
      },
      rules: {
        areaCode: [{ required: true, message: '请选择区域' }],
      },

      areaList: [],
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.config.page.pageSize = pageSize
      this.getList()
    },
    search(data) {
      this.search_data = data
      this.config.page.pageNo = 1
      this.getList()
    },
    add() {
      this.modal = {
        show: true,
        title: '新增区域',
        submitLoading: false,
      }
    },
    getList() {
      this.table.loading = true
      this.$post(this.$api.USER_AREA_MANAGE.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        ...this.search_data,
        userId: this.regionRecordsList.id,
      })
        .then((res) => {
          this.table.data = res.list
          this.config.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.modal = {
        show: true,
        title: '编辑区域',
        submitLoading: false,
      }
    },

    del(id) {
      this.$post(this.$api.USER_AREA_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success('删除成功')
        this.getList()
      })
    },

    modalShow(visible) {
      if (visible) return
      this.form = {
        userName: this.form.userName,
        userId: this.form.userId,
        areaCode: '',
        areaName: '',
        createTime: '',
      }
      this.$refs.form.resetFields()
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        this.modal.submitLoading = true
        this.$post(
          params.id
            ? this.$api.USER_AREA_MANAGE.EDIT
            : this.$api.USER_AREA_MANAGE.ADD,
          params
        )
          .then(() => {
            this.getList()
            this.modal.show = false
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },
    modalChange(visible) {
      this.form = {
        userName: this.regionRecordsList.name,
        userId: this.regionRecordsList.id,
        areaCode: '',
        areaName: '',
        createTime: '',
      }
      this.getList()
    },

    //省市区列表接口
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: '500000',
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          item.loading = false
          item.children = []
        })
        this.areaList = res
      })
    },
    loadData(item, callback) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name
            item.value = item.id
            if (item.hasChild == 'true') {
              item.loading = false
              item.children = []
            }
          })
          item.children = res
          callback()
        })
        .finally(() => {
          item.loading = false
        })
    },
    handleCascader(v, o) {
      this.form.areaCode = v[v.length - 1]
      this.form.areaName = o[o.length - 1].name
    },
  },
  mounted() {
    this.getAreaList()
    this.getList()
  },
}
</script>

<style lang="less" scoped>
.user-manage-page {
  width: 100%;
  height: 100%;
}
</style>